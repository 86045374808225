import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as Sentry from '@sentry/browser/esm';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import processWeChatPayParameters from 'utils/WeChat/processWeChatPayParameters';
import routes from 'utils/routes';
import { resetCart } from 'containers/CartProvider/slices';
import { toggleMiniCart } from 'containers/App/slices';
import { getOrder, useGetPaymentMethodsByQuoiteId } from 'containers/OrderDetailPage/queries';
import appMessages from 'containers/App/messages';
import { selectCommissionMonthSelected } from 'containers/CheckoutPage/slices';
import toHuiPayParameters from 'utils/WeChat/toHuiPayParameters';
import { AVAILABLE_PAYMENT_METHODS, PAYMENT_METHODS } from './constants';

export const createCheckoutQuote = () => request('POST', '/carts/mine');
export const getCheckoutQuote = () => request('GET', '/carts/mine');
export const addToCheckoutQuote = payload => request('POST', '/carts/mine/items', payload);
export const fetchWeChatQrPayParamaters = (id, vendorCode, isOrder = true, payload = {}) =>
  request(
    'GET',
    `/vendor/wechatpay/getqrcode/${id}/?quoteIdIsOrderId=${isOrder.toString()}&vendorCode=${vendorCode}`,
    payload,
  );
export const fetchAlipayQrPayParamaters = (quoteId, payload) =>
  request('GET', `/alipay/qrcode/quote/${quoteId}`, payload);
export const fetchChinapayFormParamaters = (quoteId, payload) =>
  request('GET', `/chinapay/payment_params/quote_id/${quoteId}/`, payload);
export const fetchChinapayFormActionUrl = payload =>
  request('GET', '/chinapay/front/payment', payload);
export const fetchHuiFuAliPay = (orderId, vendorCode) =>
  request('GET', `/huifupay/alipay/payment_params/quote_id/${orderId}/${vendorCode}`);
export const fetchHuiFuWechatPay = (orderId, vendorCode) =>
  request('GET', `/huifupay/wechat/payment_params/quote_id/${orderId}/${vendorCode}`);
export const fetchHuiFuUnionPay = (orderId, vendorCode) =>
  request('GET', `/huifupay/union/payment_params/quote_id/${orderId}/${vendorCode}`);
export const moveToCheckoutQuote = payload =>
  request('POST', '/partial-checkout/checkout/add', payload);
export const clearCheckoutQuote = () => request('PUT', '/partial-checkout/checkout/clear');
export const estimateShippingCosts = payload =>
  request('POST', '/carts/mine/estimate-shipping-methods-by-address-id', payload);
export const addShippingInformation = payload =>
  request('POST', '/carts/mine/shipping-information', payload);
export const addBillingInformation = payload =>
  request('POST', '/carts/mine/billing-address', payload);
export const getCheckoutTotals = () => request('GET', '/carts/mine/totals');
export const placeOrder = (payload, isBenefitDate) =>
  request(
    'PUT',
    isBenefitDate ? `/carts/mine/order?is_benefit_date=${isBenefitDate}` : '/carts/mine/order',
    payload,
  );
export const fetchWeChatPayParamaters = (id, vendorCode, isOrder = true, payload = {}) => {
  const openid = localStorage.getItem('openid') || '';
  return request(
    'GET',
    `/vendor/wechatpay/geturl/${id}/?${qs.stringify({
      quoteIdIsOrderId: isOrder.toString(),
      vendorCode,
      ...(openid ? { openid } : null),
    })}`,
    payload,
  );
};
export const checkPoints = () => request('GET', '/loyalty/use-points');
export const deployPoints = () => request('PUT', '/loyalty/use-points');
export const removePoints = () => request('DELETE', '/loyalty/use-points');
export const getPaymentMethods = cartId => request('GET', `/carts/${cartId}/payment-methods`);
export const getMinePaymentMethods = () => request('GET', '/carts/mine/payment-methods');
export const getMineShippingMethods = () => request('GET', '/carts/mine/shipping-methods');
export const getSelectedPaymentMethod = () => request('GET', '/carts/mine/selected-payment-method');
export const getLoyaltyPoints = () => request('GET', '/loyalty/points-payout');

// eslint-disable-next-line import/prefer-default-export
export const useGetLoyaltyPoints = () =>
  useQuery(['loyalty.pointsPayout'], () => getLoyaltyPoints(), querySetting);
export const useDeployPoints = () => useMutation(() => deployPoints());
export const useRemovePoints = () => useMutation(() => removePoints());
export const useToggleUserCredit = () =>
  useMutation(toggle => (toggle ? deployPoints() : removePoints()));
export const useMoveToCheckoutQuote = () => useMutation(payload => moveToCheckoutQuote(payload));
export const useClearCheckoutQuote = () => useMutation(() => clearCheckoutQuote());
export const useAddToCheckoutQuote = () => useMutation(payload => addToCheckoutQuote(payload));

export const useGetCheckoutQuote = () => {
  const history = useHistory();
  return useMutation(() => getCheckoutQuote(), {
    onSuccess: quote => {
      if (isEmpty(quote.items) && window.location.pathname === routes.checkout()) {
        history.push(routes.dashboard);
      }
    },
  });
};

export const useCheckPoints = () => useMutation(() => checkPoints());

export const useGetCheckoutQuoteQuery = () => {
  const history = useHistory();
  return useQuery(['checkout.quote'], () => getCheckoutQuote(), {
    ...querySetting,
    cacheTime: 0,
    onSuccess: quote => {
      if (isEmpty(quote.items) && window.location.pathname === routes.checkout()) {
        history.push(routes.dashboard);
      }
    },
  });
};

export const useGetCheckoutTotals = () =>
  useQuery(['checkout.totals'], () => getCheckoutTotals(), querySetting);

export const useGetPaymentMethods = cartId =>
  useQuery(cartId && ['checkout.paymentMethods', cartId], () => getPaymentMethods(cartId), {
    ...querySetting,
    enabled: !!cartId,
  });

export const useGetMinePaymentMethods = () =>
  useQuery(['checkout.minePaymentMethods'], () => getMinePaymentMethods(), querySetting);

export const useGetAvailableMinePaymentMethods = () => {
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState();
  const { data: methods, ...other } = useGetMinePaymentMethods();
  useEffect(() => {
    if (methods) {
      const availableMethods = methods?.filter(m => AVAILABLE_PAYMENT_METHODS.includes(m.code));
      setAvailablePaymentMethods(availableMethods);
    }
  }, [methods]);
  return { data: availablePaymentMethods, ...other };
};

export const useSelectShippingAddress = () =>
  useMutation(payload => estimateShippingCosts(payload), {
    mutationKey: 'checkout.shippingMethod',
  });
export const useAddShippingInformation = () =>
  useMutation(payload => addShippingInformation(payload), {
    mutationKey: 'checkout.addShippingInformationQuery',
  });
export const useProcessWeChatPayParameters = () =>
  useMutation(({ wechatpayParameters, orderId }) =>
    processWeChatPayParameters(wechatpayParameters, orderId),
  );

export const useGetSelectedPaymentMethod = () =>
  useQuery(['checkout.selectedPaymentMethod'], () => getSelectedPaymentMethod(), querySetting);

export const usePlaceOrder = (order = {}) => {
  const history = useHistory();
  const { data: paymentMethods } = useGetPaymentMethodsByQuoiteId(order.id);
  const { data: totals } = useGetCheckoutTotals();
  const commissionMonthSelected = useSelector(selectCommissionMonthSelected);
  const dispatch = useDispatch();

  return useMutation(
    async () => {
      const method = totals.grand_total ? paymentMethods?.[0].code : 'free';

      const isBenefitDate = commissionMonthSelected?.code === 'last' ? 1 : '';
      return placeOrder({ paymentMethod: { method } }, isBenefitDate);
    },
    {
      onSuccess: () => {
        dispatch(resetCart());
      },
      onError: () => {
        dispatch(toggleMiniCart(true));
        history.goBack();
      },
    },
  );
};

export const usePayment = () => {
  const notify = useNotificationSnackbar();
  const intl = useIntl();
  const isMin = determineEnvironment();

  return useMutation(async ({ orderId, vendorCode, paymentMethod }) => {
    if (paymentMethod === PAYMENT_METHODS.H_WECHAT && isMin) {
      toHuiPayParameters(orderId, vendorCode, paymentMethod).catch(err => {
        notify('支付参数获取失败' || err, 'error');

        Sentry.withScope(scope => {
          scope.setExtra('toHuiPayParameters', { orderId, vendorCode, paymentMethod });
          scope.setTag('vendorCode', vendorCode);
          scope.setTag('paymentMethod', paymentMethod);
          Sentry.captureException(err);
        });

        throw err;
      });
      return null;
    }

    if (paymentMethod === PAYMENT_METHODS.WECHAT) {
      if (!vendorCode) {
        const order = await getOrder(orderId);
        // eslint-disable-next-line no-param-reassign
        vendorCode = order?.extension_attributes?.vendor_code;
      }
      if (isMin) {
        const wechatpayParameters = await fetchWeChatPayParamaters(orderId, vendorCode);
        console.log('fetchWeChatPayParamaters', wechatpayParameters);
        return processWeChatPayParameters(wechatpayParameters, orderId).catch(err => {
          // Notification - Something went wrong
          const message = err.message || '未知错误';
          notify(
            '支付参数获取失败' || `${message}: ${JSON.stringify(wechatpayParameters)}, ${orderId}`,
            'error',
          );

          Sentry.withScope(scope => {
            scope.setExtra('processWeChatPayParameters', { orderId, vendorCode, paymentMethod });
            scope.setTag('vendorCode', vendorCode);
            scope.setTag('paymentMethod', paymentMethod);
            Sentry.captureException(err);
          });

          console.log('Notification - Something went wrong', wechatpayParameters, orderId, err);
          throw err;
        });
      }
      const wechatpayParameters = await fetchWeChatQrPayParamaters(orderId, vendorCode).then(
        res => {
          if (res?.[0] === 'Ok') {
            return res;
          }
          notify('支付参数获取失败' || res?.[1], 'error');

          Sentry.withScope(scope => {
            scope.setExtra('wechatpayParameters', { orderId, vendorCode, paymentMethod });
            scope.setTag('vendorCode', vendorCode);
            scope.setTag('paymentMethod', paymentMethod);
            Sentry.captureException(new Error(res?.[1]));
          });

          throw new Error(res?.[1]);
        },
      );
      return wechatpayParameters;
    }
    if (paymentMethod === PAYMENT_METHODS.ALI) {
      const alipayParameters = await fetchAlipayQrPayParamaters(orderId);
      return alipayParameters;
    }
    if (paymentMethod === PAYMENT_METHODS.CHINA_PAY) {
      const chinapayParameters = await fetchChinapayFormParamaters(orderId);
      const ChinapayFormActionUrl = await fetchChinapayFormActionUrl();
      return [chinapayParameters, ChinapayFormActionUrl];
    }

    if (paymentMethod === PAYMENT_METHODS.H_ALI) {
      const huiFuAlipay = await fetchHuiFuAliPay(orderId, vendorCode);
      return huiFuAlipay?.[1];
    }

    if (paymentMethod === PAYMENT_METHODS.H_WECHAT) {
      const huiFuWechatPay = await fetchHuiFuWechatPay(orderId, vendorCode);
      return huiFuWechatPay?.[1];
    }

    if (paymentMethod === PAYMENT_METHODS.H_UNION) {
      const huifuUnionPay = await fetchHuiFuUnionPay(orderId, vendorCode);
      return huifuUnionPay?.[1];
    }

    const message = intl.formatMessage(appMessages.noPaymentMethods);
    notify(message, 'error');
    throw message;
  });
};

export const useOthersBuy = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return useMutation(async payload => {
    const cartItems = payload.items ? payload.items : [];
    const itemIds = cartItems.map(item => item.item_id);
    if (isEmpty(cartItems)) {
      return;
    }
    if (!isEmpty(itemIds)) {
      await moveToCheckoutQuote({ itemIds });
      history.push(routes.checkout());
      dispatch(toggleMiniCart(false));
    }
  });
};
